<template>
  <div>
    <!-- section (랜딩페이지) -->
    <div class="d-flex align-start justify-center" style="width: 100%; background-color: #FCFEFF">
      <!-- 웹 -->
      <div class="d-none d-sm-flex align-center" style="width: 100%; height: 100%; position: relative">
        <!-- 1904 * 868.69 -->
        <img src="@/assets/랜딩페이지_3x.jpg" style="width: 100%; height: 100%; object-fit: contain">
        <!-- 리컬럼 버튼 -->
        <div class="d-flex align-center justify-center" @click="goRecolumn()"
          style="position: absolute; top: calc(418 / 876 * 100%); left: calc(320 / 1920 * 100%); height: 56px; border-radius: 8px; background-color: #0D0E17; padding: 0px 16px 0px 20px; cursor: pointer"
        >
          <div style="font-size: 18px; font-weight: 700; line-height: 22px; color: #ffffff">
            리컬럼으로 이동하기
          </div>
          <v-icon style="font-size: 24px; color: #ffffff; margin-left: 4px">
            mdi-arrow-right
          </v-icon>
        </div>
      </div>
      <!-- 모바일 -->
      <div class="d-sm-none d-flex align-center" style="width: 100%; height: 100%; position: relative">
        <img src="@/assets/랜딩페이지_모바일_2x.jpg" style="width: 100%; height: 100%; object-fit: contain">
        <!-- 리컬럼 버튼 -->
        <div class="d-flex align-center justify-center" @click="goRecolumn()"
          style="position: absolute; bottom: calc(64 / 1064 * 100%); left: calc(50% - 99px); height: 56px; border-radius: 8px; background-color: #0D0E17; padding: 0px 16px 0px 20px; cursor: pointer"
        >
          <div style="font-size: 18px; font-weight: 700; line-height: 22px; color: #ffffff">
            리컬럼으로 이동하기
          </div>
          <v-icon style="font-size: 24px; color: #ffffff; margin-left: 4px">
            mdi-arrow-right
          </v-icon>
        </div>
      </div>
    </div>

    <!-- section (협력업체) -->
    <div class="d-flex justify-center">
      <!-- 웹 (1920*1080 이상) -->
      <div class="d-none d-xl-block" style="width: 60%; padding: 80px 0px 80px 0px">
        <div style="width: 100%">
          <split-carousel
            :loop="loop"
            :autoplay="autoplay"
            :speed="speed"
            :interval="interval"
            :item-width="240"
            :height="240"
            :item-align="itemAlign"
            :hover-cancel-play="hoverCancelPlay"
            :display-amount="displayAmount"
            :arrow-visible="arrowVisible"
            :timing-function="timingFuntion"
            :arrow-area-width="arrowAreaWidth"
            :play-direction="playDirection"
            :key="carouselKey1"
          >
            <h3 slot="left-arrow"></h3>
            <split-carousel-item>
              <div class="carousel-item">
                <img src="@/assets/partners/partner_1.jpg" style="width: 40%; height: auto">
              </div>
            </split-carousel-item>
            <split-carousel-item>
              <div class="carousel-item">
                <img src="@/assets/partners/partner_2.jpg" style="width: 40%; height: auto">
              </div>
            </split-carousel-item>
            <split-carousel-item>
              <div class="carousel-item">
                <img src="@/assets/partners/partner_3.png" style="width: 80%; height: auto">
              </div>
            </split-carousel-item>
            <split-carousel-item>
              <div class="carousel-item">
                <img src="@/assets/partners/partner_4.png" style="width: 60%; height: auto">
              </div>
            </split-carousel-item>
            <split-carousel-item>
              <div class="carousel-item">
                <img src="@/assets/partners/partner_5.png" style="width: 80%; height: auto">
              </div>
            </split-carousel-item>
            <split-carousel-item>
              <div class="carousel-item">
                <img src="@/assets/partners/partner_6.jpg" style="width: 80%; height: auto">
              </div>
            </split-carousel-item>
            <split-carousel-item>
              <div class="carousel-item">
                <img src="@/assets/partners/partner_7.jpg" style="width: 70%; height: auto">
              </div>
            </split-carousel-item>
            <split-carousel-item>
              <div class="carousel-item">
                <img src="@/assets/partners/partner_8.jpg" style="width: 80%; height: auto">
              </div>
            </split-carousel-item>
            <h3 slot="right-arrow"></h3>
          </split-carousel>
        </div>
      </div>
      <!-- 웹 (1920*1080 미만) -->
      <div class="d-none d-xl-none d-sm-block" style="width: 80%; padding: 80px 0px 80px 0px">
        <div style="width: 100%">
          <split-carousel
            :loop="loop"
            :autoplay="autoplay"
            :speed="speed"
            :interval="interval"
            :item-width="200"
            :height="200"
            :item-align="itemAlign"
            :hover-cancel-play="hoverCancelPlay"
            :display-amount="displayAmount"
            :arrow-visible="arrowVisible"
            :timing-function="timingFuntion"
            :arrow-area-width="arrowAreaWidth"
            :play-direction="playDirection"
            :key="carouselKey2"
          >
            <h3 slot="left-arrow"></h3>
            <split-carousel-item>
              <div class="carousel-item">
                <img src="@/assets/partners/partner_1.jpg" style="width: 40%; height: auto">
              </div>
            </split-carousel-item>
            <split-carousel-item>
              <div class="carousel-item">
                <img src="@/assets/partners/partner_2.jpg" style="width: 40%; height: auto">
              </div>
            </split-carousel-item>
            <split-carousel-item>
              <div class="carousel-item">
                <img src="@/assets/partners/partner_3.png" style="width: 80%; height: auto">
              </div>
            </split-carousel-item>
            <split-carousel-item>
              <div class="carousel-item">
                <img src="@/assets/partners/partner_4.png" style="width: 60%; height: auto">
              </div>
            </split-carousel-item>
            <split-carousel-item>
              <div class="carousel-item">
                <img src="@/assets/partners/partner_5.png" style="width: 80%; height: auto">
              </div>
            </split-carousel-item>
            <split-carousel-item>
              <div class="carousel-item">
                <img src="@/assets/partners/partner_6.jpg" style="width: 80%; height: auto">
              </div>
            </split-carousel-item>
            <split-carousel-item>
              <div class="carousel-item">
                <img src="@/assets/partners/partner_7.jpg" style="width: 70%; height: auto">
              </div>
            </split-carousel-item>
            <split-carousel-item>
              <div class="carousel-item">
                <img src="@/assets/partners/partner_8.jpg" style="width: 80%; height: auto">
              </div>
            </split-carousel-item>
            <h3 slot="right-arrow"></h3>
          </split-carousel>
        </div>
      </div>
      <!-- 모바일 -->
      <div class="d-sm-none d-block" style="width: 100%; padding: 0px 40px 100px 40px">
        <split-carousel
          :loop="loop"
          :autoplay="autoplay"
          :speed="speed"
          :interval="interval"
          :item-width="mobileItemSize"
          :height="mobileItemSize"
          :item-align="itemAlign"
          :hover-cancel-play="hoverCancelPlay"
          :display-amount="2"
          :arrow-visible="arrowVisible"
          :timing-function="timingFuntion"
          :arrow-area-width="arrowAreaWidth"
          :play-direction="playDirection"
          :key="carouselKey3"
        >
          <h3 slot="left-arrow"></h3>
          <split-carousel-item>
            <div class="carousel-item">
              <img src="@/assets/partners/partner_1.jpg" style="width: 60%; height: auto">
            </div>
          </split-carousel-item>
          <split-carousel-item>
            <div class="carousel-item">
              <img src="@/assets/partners/partner_2.jpg" style="width: 60%; height: auto">
            </div>
          </split-carousel-item>
          <split-carousel-item>
            <div class="carousel-item">
              <img src="@/assets/partners/partner_3.png" style="width: 60%; height: auto">
            </div>
          </split-carousel-item>
          <split-carousel-item>
            <div class="carousel-item">
              <img src="@/assets/partners/partner_4.png" style="width: 80%; height: auto">
            </div>
          </split-carousel-item>
          <split-carousel-item>
            <div class="carousel-item">
              <img src="@/assets/partners/partner_5.png" style="width: 100%; height: auto">
            </div>
          </split-carousel-item>
          <split-carousel-item>
            <div class="carousel-item">
              <img src="@/assets/partners/partner_6.jpg" style="width: 100%; height: auto">
            </div>
          </split-carousel-item>
          <split-carousel-item>
            <div class="carousel-item">
              <img src="@/assets/partners/partner_7.jpg" style="width: 100%; height: auto">
            </div>
          </split-carousel-item>
          <split-carousel-item>
            <div class="carousel-item">
              <img src="@/assets/partners/partner_8.jpg" style="width: 100%; height: auto">
            </div>
          </split-carousel-item>
          <h3 slot="right-arrow"></h3>
        </split-carousel>
      </div>
    </div>

    <!-- section (회사소개) -->
    <div class="d-flex align-start justify-center" style="background-color: #FAFBFD">
      <!-- 웹 (1920*1080 이상) -->
      <div class="d-none d-xl-block" style="width: 60%; padding: 120px 0px 120px 0px">
        <div class="d-flex align-center justify-space-between">
          <div style="width: 60%">
            <div style="font-size: 48px; font-weight: 700" class="pb-0">
              코그콤과 함께 오늘도
            </div>
            <div style="font-size: 48px; font-weight: 700" class="pb-5">
              미래를 한 걸음 앞당기세요
            </div>
            <div style="width: 80%; font-size: 18px; font-weight: 400; color: #404040; line-height: 2">
              코그콤은 그동안 제공해온 디지털 트랜스포메이션(DX: Digital Transformation) 기술 통합하여 클라우드 기반 노코드 데이터 통합 업무 자동화 플랫폼 리컬럼(ReColumn)을 출시하였습니다. 비효율, 더 이상 방치하지 마세요.
            </div>
          </div>
          <div style="width: 40%">
            <img src="@/assets/img/team.png" style="width: 100%; height: auto">
          </div>
        </div>
      </div>
      <!-- 웹 (1920*1080 미만) -->
      <div class="d-none d-xl-none d-sm-flex flex-column align-center justify-center" style="width: 80%; padding: 120px 0px 100px 0px">
        <div class="d-flex align-center justify-space-between">
          <div style="width: 60%">
            <div style="font-size: 48px; font-weight: 700" class="pb-0">
              코그콤과 함께 오늘도
            </div>
            <div style="font-size: 48px; font-weight: 700" class="pb-5">
              미래를 한 걸음 앞당기세요
            </div>
            <div style="width: 80%; font-size: 18px; font-weight: 400; color: #404040; line-height: 2">
              코그콤은 그동안 제공해온 디지털 트랜스포메이션(DX: Digital Transformation) 기술 통합하여 클라우드 기반 노코드 데이터 통합 업무 자동화 플랫폼 리컬럼(ReColumn)을 출시하였습니다. 비효율, 더 이상 방치하지 마세요.
            </div>
          </div>
          <div style="width: 40%">
            <img src="@/assets/img/team.png" style="width: 100%; height: auto">
          </div>
        </div>
      </div>
      <!-- 모바일 -->
      <div class="d-sm-none d-flex flex-column align-center" style="width: 90%; padding: 60px 0px 60px 0px">
        <div class="d-flex flex-column align-center justify-space-between">
          <div class="text-center" style="width: 100%">
            <div style="font-size: 24px; font-weight: 700" class="pb-0">
              코그콤과 함께 오늘도
            </div>
            <div style="font-size: 24px; font-weight: 700" class="pb-5">
              미래를 한 걸음 앞당기세요
            </div>
            <div style="width: 100%; font-size: 16px; font-weight: 400; color: #404040; line-height: 2">
              코그콤은 그동안 제공해온 디지털 트랜스포메이션(DX: Digital Transformation) 기술 통합하여 클라우드 기반 노코드 데이터 통합 업무 자동화 플랫폼 리컬럼(ReColumn)을 출시하였습니다. 비효율, 더 이상 방치하지 마세요.
            </div>
          </div>
          <div style="width: 100%">
            <img src="@/assets/img/team.png" style="width: 100%; height: auto">
          </div>
        </div>
      </div>
    </div>

    <!-- section (클라우드 기반 노코드 업무 자동화 플랫폼 리컬럼) -->
    <div class="d-flex align-start justify-center">
      <!-- 웹 (1920*1080 이상) -->
      <div class="d-none d-xl-block text-center" style="width: 60%; padding: 140px 0px 0px 0px">
        <div style="font-size: 48px; font-weight: 700">클라우드 기반 노코드 업무 자동화 플랫폼 리컬럼</div>
      </div>
      <!-- 웹 (1920*1080 미만) -->
      <div class="d-none d-xl-none d-sm-block text-center" style="width: 80%; padding: 140px 0px 0px 0px">
        <div style="font-size: 48px; font-weight: 700">클라우드 기반 노코드 업무 자동화 플랫폼 리컬럼</div>
      </div>
      <!-- 모바일 -->
      <div class="d-sm-none d-block text-center" style="width: 90%; padding: 60px 0px 0px 0px">
        <div style="font-size: 24px; font-weight: 700">클라우드 기반 <br> 노코드 업무 자동화 플랫폼 <br> 리컬럼</div>
      </div>
    </div>

    <!-- section (클라우드 기반 노코드 업무 자동화) -->
    <div class="d-flex align-start justify-center">
      <!-- 웹 (1920*1080 이상) -->
      <div class="d-none d-xl-flex align-center justify-space-between" style="width: 60%; padding: 120px 0px 0px 0px">
        <div style="width: 60%">
          <img src="@/assets/img/recolumn_1.jpg" style="width: 100%; height: auto">
        </div>
        <div style="width: 35%">
          <div style="font-size: 18px; font-weight: 600; color: #283593" class="pb-3">
            Cloud RPA: 리컬럼 업무 자동화
          </div>
          <div style="font-size: 36px; font-weight: 700" class="pb-6">
            노코드 업무 자동화
          </div>
          <div style="font-size: 18px; color: #404040; line-height: 2" class="pb-0">
            PDF, MS Excel 등의 데이터, 기업 ERP 데이터를 
            처음 사용하는 사용자도 예시 선택 만으로 
            자동화를 적용할 수 있습니다.
            반복되는 업무를 자동화하여 
            디지털 트랜스포메이션(DX: Digital 
            Transformation)의 
            놀라운 효과를 경험하세요.
            골치 아픈 데이터 업무를 자동화하여 기업의 자동화 
            생산성 뿐만 아니라 품질 향상에도 기여합니다.
          </div>
        </div>
      </div>
      <!-- 웹 (1920*1080 미만) -->
      <div class="d-none d-xl-none d-sm-flex align-center justify-space-between" style="width: 80%; padding: 120px 0px 0px 0px">
        <div style="width: 55%">
          <img src="@/assets/img/recolumn_1.jpg" style="width: 100%; height: auto">
        </div>
        <div style="width: 40%">
          <div style="font-size: 18px; font-weight: 600; color: #283593" class="pb-3">
            Cloud RPA: 리컬럼 업무 자동화
          </div>
          <div style="font-size: 36px; font-weight: 700" class="pb-6">
            노코드 업무 자동화
          </div>
          <div style="font-size: 18px; color: #404040; line-height: 2" class="pb-0">
            PDF, MS Excel 등의 데이터, 기업 ERP 데이터를 
            처음 사용하는 사용자도 예시 선택 만으로 
            자동화를 적용할 수 있습니다.
            반복되는 업무를 자동화하여 
            디지털 트랜스포메이션(DX: Digital 
            Transformation)의 
            놀라운 효과를 경험하세요.
            골치 아픈 데이터 업무를 자동화하여 기업의 자동화 
            생산성 뿐만 아니라 품질 향상에도 기여합니다.
          </div>
        </div>
      </div>
      <!-- 모바일 -->
      <div class="d-sm-none d-flex flex-column align-center" style="width: 90%; padding: 60px 0px 0px 0px">
        <div style="width: 100%" class="pb-6">
          <img src="@/assets/img/recolumn_1.jpg" style="width: 100%; height: auto">
        </div>
        <div style="width: 100%" class="text-center">
          <div style="font-size: 16px; font-weight: 600; color: #283593" class="pb-1">
            Cloud RPA: 리컬럼 업무 자동화
          </div>
          <div style="font-size: 24px; font-weight: 700" class="pb-3">
            노코드 업무 자동화
          </div>
          <div style="font-size: 16px; color: #404040; line-height: 2" class="pb-0">
            PDF, MS Excel 등의 데이터, 기업 ERP 데이터를 
            처음 사용하는 사용자도 예시 선택 만으로 
            자동화를 적용할 수 있습니다.
            반복되는 업무를 자동화하여 
            디지털 트랜스포메이션(DX: Digital 
            Transformation)의 
            놀라운 효과를 경험하세요.
            골치 아픈 데이터 업무를 자동화하여 기업의 자동화 
            생산성 뿐만 아니라 품질 향상에도 기여합니다.
          </div>
        </div>
      </div>
    </div>

    <!-- section (클라우드 기반 데이터 통합) -->
    <div class="d-flex align-start justify-center">
      <!-- 웹 (1920*1080 이상) -->
      <div class="d-none d-xl-flex align-center justify-space-between" style="width: 60%; padding: 200px 0px 0px 0px">
        <div style="width: 60%">
          <img src="@/assets/img/recolumn_2.jpg" style="width: 100%; height: auto">
        </div>
        <div style="width: 35%">
          <div style="font-size: 18px; font-weight: 600; color: #283593" class="pb-3">
            Cloud Data Integration: 리컬럼 데이터 통합
          </div>
          <div style="font-size: 36px; font-weight: 700" class="pb-6">
            클라우드 기반 데이터 통합
          </div>
          <div style="font-size: 18px; color: #404040; line-height: 2" class="pb-0">
            PDF, MS Excel 등의 데이터, 기업 ERP, 
            Database와 같은 기업 시스템도 
            몇 번의 클릭만으로 통합하고 자동화하세요.
            데이터 통합으로 시작하는 디지털 트랜스포메이션
            (DX: Digital Transformation)의
            놀라운 효율을 경험하세요.
            골치 아픈 데이터 작업과 공유시간을 단축하여 
            기업의 협업 생산성 뿐만 아니라 
            품질 향상에도 기여합니다.
          </div>
        </div>
      </div>
      <!-- 웹 (1920*1080 미만) -->
      <div class="d-none d-xl-none d-sm-flex align-center justify-space-between" style="width: 80%; padding: 200px 0px 0px 0px">
        <div style="width: 55%">
          <img src="@/assets/img/recolumn_2.jpg" style="width: 100%; height: auto">
        </div>
        <div style="width: 40%">
          <div style="font-size: 18px; font-weight: 600; color: #283593" class="pb-3">
            Cloud Data Integration: 리컬럼 데이터 통합
          </div>
          <div style="font-size: 36px; font-weight: 700" class="pb-6">
            클라우드 기반 데이터 통합
          </div>
          <div style="font-size: 18px; color: #404040; line-height: 2" class="pb-0">
            PDF, MS Excel 등의 데이터, 기업 ERP, 
            Database와 같은 기업 시스템도 
            몇 번의 클릭만으로 통합하고 자동화하세요.
            데이터 통합으로 시작하는 디지털 트랜스포메이션
            (DX: Digital Transformation)의
            놀라운 효율을 경험하세요.
            골치 아픈 데이터 작업과 공유시간을 단축하여 
            기업의 협업 생산성 뿐만 아니라 
            품질 향상에도 기여합니다.
          </div>
        </div>
      </div>
      <!-- 모바일 -->
      <div class="d-sm-none d-flex flex-column align-center" style="width: 90%; padding: 60px 0px 0px 0px">
        <div style="width: 100%" class="pb-6">
          <img src="@/assets/img/recolumn_2.jpg" style="width: 100%; height: auto">
        </div>
        <div style="width: 100%" class="text-center">
          <div style="font-size: 16px; font-weight: 600; color: #283593" class="pb-1">
            Cloud Data Integration: 리컬럼 데이터 통합
          </div>
          <div style="font-size: 24px; font-weight: 700" class="pb-3">
            클라우드 기반 데이터 통합
          </div>
          <div style="font-size: 16px; color: #404040; line-height: 2" class="pb-0">
            PDF, MS Excel 등의 데이터, 기업 ERP, 
            Database와 같은 기업 시스템도 
            몇 번의 클릭만으로 통합하고 자동화하세요.
            데이터 통합으로 시작하는 디지털 트랜스포메이션
            (DX: Digital Transformation)의
            놀라운 효율을 경험하세요.
            골치 아픈 데이터 작업과 공유시간을 단축하여 
            기업의 협업 생산성 뿐만 아니라 
            품질 향상에도 기여합니다.
          </div>
        </div>
      </div>
    </div>

    <!-- section (바로가기 버튼) -->
    <div class="d-flex align-start justify-center">
      <!-- 웹 (1920*1080 이상) -->
      <div class="d-none d-xl-block text-center" style="width: 60%; padding: 120px 0px 120px 0px">
        <v-btn color="#367BF5" x-large outlined style="background-color: white" @click="goRecolumn()">바로가기</v-btn>
      </div>
      <!-- 웹 (1920*1080 미만) -->
      <div class="d-none d-xl-none d-sm-block text-center" style="width: 80%; padding: 120px 0px 120px 0px">
        <v-btn color="#367BF5" x-large outlined style="background-color: white" @click="goRecolumn()">바로가기</v-btn>
      </div>
      <!-- 모바일 -->
      <div class="d-sm-none d-block text-center" style="width: 90%; padding: 60px 0px 60px 0px">
        <v-btn color="#367BF5" x-large outlined style="background-color: white" @click="goRecolumn()">바로가기</v-btn>
      </div>
    </div>

    <!-- section (인공지능 솔루션) -->
    <div class="d-flex align-start justify-center" style="background-color: #FAFBFD">
      <!-- 웹 (1920*1080 이상) -->
      <div class="d-none d-xl-flex flex-column align-center justify-center" style="width: 60%; padding: 120px 0px 120px 0px">
        <div style="font-size: 48px; font-weight: 700; padding-bottom: 90px" class="text-center">
          인공지능 솔루션
        </div>
        <!-- 1 -->
        <div class="d-flex align-center justify-space-between" style="width: 100%">
          <div style="width: 40%">
            <div style="font-size: 18px; font-weight: 600; color: #283593" class="pb-3">
              종이문서 + 전자문서
            </div>
            <div class="ai-solution-title pb-4">
              기업 데이터 솔루션
            </div>
            <div class="ai-solution-body pb-5">
              협업 과정에서 발생하는 다양한 문서를 OCR(Optical Character Recognition) + 업무자동화(RPA: Robotic Process Automation) + 데이터통합(Data Integration) 기술을 적용하여 IT 팀의 도움 없이도 엑셀 파일 형태로 업무가 편리하도록 정리해드립니다.
            </div>
            <div class="mb-8">
              <div class="d-flex align-start mb-4">
                <v-icon style="color: #2096f3">mdi-check-circle</v-icon>
                <div style="font-size: 18px; font-weight: 400" class="pl-2">
                  온프레미스 & 클라우드 환경 모두 지원
                </div>
              </div>
              <div class="d-flex align-start mb-4">
                <v-icon style="color: #2096f3">mdi-check-circle</v-icon>
                <div style="font-size: 18px; font-weight: 400" class="pl-2">
                  종이 문서를 전자문서화
                </div>
              </div>
              <div class="d-flex align-start mb-4">
                <v-icon style="color: #2096f3">mdi-check-circle</v-icon>
                <div style="font-size: 18px; font-weight: 400" class="pl-2">
                  전자문서를 데이터베이스화
                </div>
              </div>
              <div class="d-flex align-start mb-4">
                <v-icon style="color: #2096f3">mdi-check-circle</v-icon>
                <div style="font-size: 18px; font-weight: 400" class="pl-2">
                  IT팀 없이 클릭만으로 편리한 데이터 업무 지원
                </div>
              </div>
            </div>
          </div>
          <div style="width: 55%">
            <img src="@/assets/img/solution_1.png" style="width: 100%; height: auto">
          </div>
        </div>
        <!-- 2 -->
        <div class="d-flex align-center justify-space-between" style="margin-top: 120px; width: 100%">
          <div style="width: 55%">
            <img src="@/assets/img/solution_2.png" style="width: 100%; height: auto">
          </div>
          <div style="width: 40%">
            <div style="font-size: 18px; font-weight: 600; color: #283593" class="pb-3">
              의료/바이오/헬스케어
            </div>
            <div class="ai-solution-title pb-4">
              의료 정보 표준화 기술
            </div>
            <div class="ai-solution-body pb-5">
              미국 국립 보건원(NIH: National Institutes of Health) UMLS(Unified Medical Language System) 데이터베이스를 활용하여 의료 용어 표준화를 지원합니다.
            </div>
            <div class="mb-8">
              <div class="d-flex align-start mb-4">
                <v-icon style="color: #2096f3">mdi-check-circle</v-icon>
                <div style="font-size: 18px; font-weight: 400" class="pl-2">
                  딥러닝 언어모델(LLM: Large Language Model)
                </div>
              </div>
              <div class="d-flex align-start mb-4">
                <v-icon style="color: #2096f3">mdi-check-circle</v-icon>
                <div style="font-size: 18px; font-weight: 400" class="pl-2">
                  지식그래프(Knowledge Graph)
                </div>
              </div>
              <div class="d-flex align-start mb-4">
                <v-icon style="color: #2096f3">mdi-check-circle</v-icon>
                <div style="font-size: 18px; font-weight: 400" class="pl-2">
                  전문용어 추천 기능
                </div>
              </div>
              <div class="d-flex align-start mb-4">
                <v-icon style="color: #2096f3">mdi-check-circle</v-icon>
                <div style="font-size: 18px; font-weight: 400" class="pl-2">
                  편리한 나만의 용어 관리 기능
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 3 -->
        <div class="d-flex align-center justify-space-between" style="margin-top: 120px; width: 100%">
          <div style="width: 40%">
            <div style="font-size: 18px; font-weight: 600; color: #283593" class="pb-3">
              대학교 및 연구실 대상
            </div>
            <div class="ai-solution-title pb-4">
              자동 연구 실적 정보 솔루션
            </div>
            <div class="ai-solution-body pb-5">
              논문 PDF를 업로드하면 자동으로 논문명, 저자, 저널명, 학회명, 출판일 등 주요 정보를 대학의 학사시스템에 동기화합니다.
            </div>
            <div class="mb-8">
              <div class="d-flex align-start mb-4">
                <v-icon style="color: #2096f3">mdi-check-circle</v-icon>
                <div style="font-size: 18px; font-weight: 400" class="pl-2">
                  OCR(Optical Character Recognition) 기술
                </div>
              </div>
              <div class="d-flex align-start mb-4">
                <v-icon style="color: #2096f3">mdi-check-circle</v-icon>
                <div style="font-size: 18px; font-weight: 400" class="pl-2">
                  Meta-data 추출 기술
                </div>
              </div>
            </div>
          </div>
          <div style="width: 55%">
            <img src="@/assets/img/solution_3.png" style="width: 100%; height: auto">
          </div>
        </div>
        <!-- 4 -->
        <div class="d-flex align-center justify-space-between" style="margin-top: 120px; width: 100%">
          <div style="width: 55%">
            <img src="@/assets/img/solution_4.png" style="width: 100%; height: auto">
          </div>
          <div style="width: 40%">
            <div style="font-size: 18px; font-weight: 600; color: #283593" class="pb-3">
              웹/모바일 서비스 기업
            </div>
            <div class="ai-solution-title pb-4">
              메타인지 검색 솔루션
            </div>
            <div class="ai-solution-body pb-5">
              문서 주요 구간을 포착하는 코그콤만의 특허기술을 활용하여 사용자의 의도를 자동으로 파악합니다.
            </div>
            <div class="mb-8">
              <div class="d-flex align-start mb-4">
                <v-icon style="color: #2096f3">mdi-check-circle</v-icon>
                <div style="font-size: 18px; font-weight: 400" class="pl-2">
                  엘라스틱 스택(Elastic Stack) 기반 검색 솔루션
                </div>
              </div>
              <div class="d-flex align-start mb-4">
                <v-icon style="color: #2096f3">mdi-check-circle</v-icon>
                <div style="font-size: 18px; font-weight: 400" class="pl-2">
                  사용자 히스토리를 영화 필름 보듯 시각적 탐색 제공
                </div>
              </div>
              <div class="d-flex align-start mb-4">
                <v-icon style="color: #2096f3">mdi-check-circle</v-icon>
                <div style="font-size: 18px; font-weight: 400" class="pl-2">
                  딥러닝 언어모델 기반(LLM) 동의어 모델
                </div>
              </div>
              <div class="d-flex align-start mb-4">
                <v-icon style="color: #2096f3">mdi-check-circle</v-icon>
                <div style="font-size: 18px; font-weight: 400" class="pl-2">
                  단어, 문장, 문구, 문서간의 네트워크 분석
                </div>
              </div>
            </div>
            <v-btn color="#367BF5" x-large dark @click="goInventier()">인벤티어 바로가기</v-btn>
          </div>
        </div>
        <!-- 5 -->
        <div class="d-flex align-center justify-space-between" style="margin-top: 120px; width: 100%">
          <div style="width: 40%">
            <div style="font-size: 18px; font-weight: 600; color: #283593" class="pb-3">
              비즈니스/법률/특허 문서
            </div>
            <div class="ai-solution-title pb-4">
              인공지능 문서 요약 솔루션
            </div>
            <div class="ai-solution-body pb-5">
              기업에서 운영하는 서비스의 문서, 텍스트를 고객에게 요약된 형태로 제공합니다.
            </div>
            <div class="mb-8">
              <div class="d-flex align-start mb-4">
                <v-icon style="color: #2096f3">mdi-check-circle</v-icon>
                <div style="font-size: 18px; font-weight: 400" class="pl-2">
                  검색 솔루션 연계 문서 자동 요약 기술
                </div>
              </div>
              <div class="d-flex align-start mb-4">
                <v-icon style="color: #2096f3">mdi-check-circle</v-icon>
                <div style="font-size: 18px; font-weight: 400" class="pl-2">
                  최신 딥러닝 언어 모델 기반의 AI 자연어처리 엔진
                </div>
              </div>
              <div class="d-flex align-start mb-4">
                <v-icon style="color: #2096f3">mdi-check-circle</v-icon>
                <div style="font-size: 18px; font-weight: 400" class="pl-2">
                  인공지능 그래프 분석 알고리즘으로 주요 내용 제공
                </div>
              </div>
              <div class="d-flex align-start mb-4">
                <v-icon style="color: #2096f3">mdi-check-circle</v-icon>
                <div style="font-size: 18px; font-weight: 400" class="pl-2">
                  다양한 단위의 텍스트 임베딩(Embedding) 모델
                </div>
              </div>
            </div>
          </div>
          <div style="width: 55%">
            <img src="@/assets/img/solution_5.png" style="width: 100%; height: auto">
          </div>
        </div>
      </div>
      <!-- 웹 (1920*1080 미만) -->
      <div class="d-none d-xl-none d-sm-flex flex-column align-center justify-center" style="width: 80%; padding: 120px 0px 120px 0px">
        <div style="font-size: 48px; font-weight: 700; padding-bottom: 90px" class="text-center">
          인공지능 솔루션
        </div>
        <!-- 1 -->
        <div class="d-flex align-center justify-space-between">
          <div style="width: 40%">
            <div style="font-size: 18px; font-weight: 600; color: #283593" class="pb-3">
              종이문서 + 전자문서
            </div>
            <div class="ai-solution-title pb-4">
              기업 데이터 솔루션
            </div>
            <div class="ai-solution-body pb-5">
              협업 과정에서 발생하는 다양한 문서를 OCR(Optical Character Recognition) + 업무자동화(RPA: Robotic Process Automation) + 데이터통합(Data Integration) 기술을 적용하여 IT 팀의 도움 없이도 엑셀 파일 형태로 업무가 편리하도록 정리해드립니다.
            </div>
            <div class="mb-8">
              <div class="d-flex align-start mb-4">
                <v-icon style="color: #2096f3">mdi-check-circle</v-icon>
                <div style="font-size: 18px; font-weight: 400" class="pl-2">
                  온프레미스 & 클라우드 환경 모두 지원
                </div>
              </div>
              <div class="d-flex align-start mb-4">
                <v-icon style="color: #2096f3">mdi-check-circle</v-icon>
                <div style="font-size: 18px; font-weight: 400" class="pl-2">
                  종이 문서를 전자문서화
                </div>
              </div>
              <div class="d-flex align-start mb-4">
                <v-icon style="color: #2096f3">mdi-check-circle</v-icon>
                <div style="font-size: 18px; font-weight: 400" class="pl-2">
                  전자문서를 데이터베이스화
                </div>
              </div>
              <div class="d-flex align-start mb-4">
                <v-icon style="color: #2096f3">mdi-check-circle</v-icon>
                <div style="font-size: 18px; font-weight: 400" class="pl-2">
                  IT팀 없이 클릭만으로 편리한 데이터 업무 지원
                </div>
              </div>
            </div>
          </div>
          <div style="width: 55%">
            <img src="@/assets/img/solution_1.png" style="width: 100%; height: auto">
          </div>
        </div>
        <!-- 2 -->
        <div class="d-flex align-center justify-space-between" style="margin-top: 120px">
          <div style="width: 55%">
            <img src="@/assets/img/solution_2.png" style="width: 100%; height: auto">
          </div>
          <div style="width: 40%">
            <div style="font-size: 18px; font-weight: 600; color: #283593" class="pb-3">
              의료/바이오/헬스케어
            </div>
            <div class="ai-solution-title pb-4">
              의료 정보 표준화 기술
            </div>
            <div class="ai-solution-body pb-5">
              미국 국립 보건원(NIH: National Institutes of Health) UMLS(Unified Medical Language System) 데이터베이스를 활용하여 의료 용어 표준화를 지원합니다.
            </div>
            <div class="mb-8">
              <div class="d-flex align-start mb-4">
                <v-icon style="color: #2096f3">mdi-check-circle</v-icon>
                <div style="font-size: 18px; font-weight: 400" class="pl-2">
                  딥러닝 언어모델(LLM: Large Language Model)
                </div>
              </div>
              <div class="d-flex align-start mb-4">
                <v-icon style="color: #2096f3">mdi-check-circle</v-icon>
                <div style="font-size: 18px; font-weight: 400" class="pl-2">
                  지식그래프(Knowledge Graph)
                </div>
              </div>
              <div class="d-flex align-start mb-4">
                <v-icon style="color: #2096f3">mdi-check-circle</v-icon>
                <div style="font-size: 18px; font-weight: 400" class="pl-2">
                  전문용어 추천 기능
                </div>
              </div>
              <div class="d-flex align-start mb-4">
                <v-icon style="color: #2096f3">mdi-check-circle</v-icon>
                <div style="font-size: 18px; font-weight: 400" class="pl-2">
                  편리한 나만의 용어 관리 기능
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 3 -->
        <div class="d-flex align-center justify-space-between" style="margin-top: 120px">
          <div style="width: 40%">
            <div style="font-size: 18px; font-weight: 600; color: #283593" class="pb-3">
              대학교 및 연구실 대상
            </div>
            <div class="ai-solution-title pb-4">
              자동 연구 실적 정보 솔루션
            </div>
            <div class="ai-solution-body pb-5">
              논문 PDF를 업로드하면 자동으로 논문명, 저자, 저널명, 학회명, 출판일 등 주요 정보를 대학의 학사시스템에 동기화합니다.
            </div>
            <div class="mb-8">
              <div class="d-flex align-start mb-4">
                <v-icon style="color: #2096f3">mdi-check-circle</v-icon>
                <div style="font-size: 18px; font-weight: 400" class="pl-2">
                  OCR(Optical Character Recognition) 기술
                </div>
              </div>
              <div class="d-flex align-start mb-4">
                <v-icon style="color: #2096f3">mdi-check-circle</v-icon>
                <div style="font-size: 18px; font-weight: 400" class="pl-2">
                  Meta-data 추출 기술
                </div>
              </div>
            </div>
          </div>
          <div style="width: 55%">
            <img src="@/assets/img/solution_3.png" style="width: 100%; height: auto">
          </div>
        </div>
        <!-- 4 -->
        <div class="d-flex align-center justify-space-between" style="margin-top: 120px">
          <div style="width: 55%">
            <img src="@/assets/img/solution_4.png" style="width: 100%; height: auto">
          </div>
          <div style="width: 40%">
            <div style="font-size: 18px; font-weight: 600; color: #283593" class="pb-3">
              웹/모바일 서비스 기업
            </div>
            <div class="ai-solution-title pb-4">
              메타인지 검색 솔루션
            </div>
            <div class="ai-solution-body pb-5">
              문서 주요 구간을 포착하는 코그콤만의 특허기술을 활용하여 사용자의 의도를 자동으로 파악합니다.
            </div>
            <div class="mb-8">
              <div class="d-flex align-start mb-4">
                <v-icon style="color: #2096f3">mdi-check-circle</v-icon>
                <div style="font-size: 18px; font-weight: 400" class="pl-2">
                  엘라스틱 스택(Elastic Stack) 기반 검색 솔루션
                </div>
              </div>
              <div class="d-flex align-start mb-4">
                <v-icon style="color: #2096f3">mdi-check-circle</v-icon>
                <div style="font-size: 18px; font-weight: 400" class="pl-2">
                  사용자 히스토리를 영화 필름 보듯 시각적 탐색 제공
                </div>
              </div>
              <div class="d-flex align-start mb-4">
                <v-icon style="color: #2096f3">mdi-check-circle</v-icon>
                <div style="font-size: 18px; font-weight: 400" class="pl-2">
                  딥러닝 언어모델 기반(LLM) 동의어 모델
                </div>
              </div>
              <div class="d-flex align-start mb-4">
                <v-icon style="color: #2096f3">mdi-check-circle</v-icon>
                <div style="font-size: 18px; font-weight: 400" class="pl-2">
                  단어, 문장, 문구, 문서간의 네트워크 분석
                </div>
              </div>
            </div>
            <v-btn color="#367BF5" x-large dark @click="goInventier()">인벤티어 바로가기</v-btn>
          </div>
        </div>
        <!-- 5 -->
        <div class="d-flex align-center justify-space-between" style="margin-top: 120px">
          <div style="width: 40%">
            <div style="font-size: 18px; font-weight: 600; color: #283593" class="pb-3">
              비즈니스/법률/특허 문서
            </div>
            <div class="ai-solution-title pb-4">
              인공지능 문서 요약 솔루션
            </div>
            <div class="ai-solution-body pb-5">
              기업에서 운영하는 서비스의 문서, 텍스트를 고객에게 요약된 형태로 제공합니다.
            </div>
            <div class="mb-8">
              <div class="d-flex align-start mb-4">
                <v-icon style="color: #2096f3">mdi-check-circle</v-icon>
                <div style="font-size: 18px; font-weight: 400" class="pl-2">
                  검색 솔루션 연계 문서 자동 요약 기술
                </div>
              </div>
              <div class="d-flex align-start mb-4">
                <v-icon style="color: #2096f3">mdi-check-circle</v-icon>
                <div style="font-size: 18px; font-weight: 400" class="pl-2">
                  최신 딥러닝 언어 모델 기반의 AI 자연어처리 엔진
                </div>
              </div>
              <div class="d-flex align-start mb-4">
                <v-icon style="color: #2096f3">mdi-check-circle</v-icon>
                <div style="font-size: 18px; font-weight: 400" class="pl-2">
                  인공지능 그래프 분석 알고리즘으로 주요 내용 제공
                </div>
              </div>
              <div class="d-flex align-start mb-4">
                <v-icon style="color: #2096f3">mdi-check-circle</v-icon>
                <div style="font-size: 18px; font-weight: 400" class="pl-2">
                  다양한 단위의 텍스트 임베딩(Embedding) 모델
                </div>
              </div>
            </div>
          </div>
          <div style="width: 55%">
            <img src="@/assets/img/solution_5.png" style="width: 100%; height: auto">
          </div>
        </div>
      </div>
      <!-- 모바일 -->
      <div class="d-sm-none d-flex flex-column align-center" style="width: 90%; padding: 60px 0px 60px 0px">
        <div style="font-size: 24px; font-weight: 700; padding-bottom: 60px" class="text-center">
          인공지능 솔루션
        </div>
        <!-- 1 -->
        <div class="d-flex flex-column align-start justify-space-between text-center">
          <div style="width: 100%">
            <div style="font-size: 16px; font-weight: 600; color: #283593" class="pb-3">
              종이문서 + 전자문서
            </div>
            <div style="font-size: 24px; font-weight: 700" class="pb-4">
              기업 데이터 솔루션
            </div>
            <div style="font-size: 16px; font-weight: 300" class="pb-5">
              협업 과정에서 발생하는 다양한 문서를 OCR(Optical Character Recognition) + 업무자동화(RPA: Robotic Process Automation) + 데이터통합(Data Integration) 기술을 적용하여 IT 팀의 도움 없이도 엑셀 파일 형태로 업무가 편리하도록 정리해드립니다.
            </div>
            <div style="width: 100%" class="mb-4">
              <img src="@/assets/img/solution_1.png" style="width: 100%; height: auto">
            </div>
            <div class="mb-8">
              <div class="d-flex align-start mb-4">
                <v-icon style="color: #2096f3">mdi-check-circle</v-icon>
                <div style="font-size: 16px; font-weight: 400" class="text-left pl-2">
                  온프레미스 & 클라우드 환경 모두 지원
                </div>
              </div>
              <div class="d-flex align-start mb-4">
                <v-icon style="color: #2096f3">mdi-check-circle</v-icon>
                <div style="font-size: 16px; font-weight: 400" class="text-left pl-2">
                  종이 문서를 전자문서화
                </div>
              </div>
              <div class="d-flex align-start mb-4">
                <v-icon style="color: #2096f3">mdi-check-circle</v-icon>
                <div style="font-size: 16px; font-weight: 400" class="text-left pl-2">
                  전자문서를 데이터베이스화
                </div>
              </div>
              <div class="d-flex align-start mb-4">
                <v-icon style="color: #2096f3">mdi-check-circle</v-icon>
                <div style="font-size: 16px; font-weight: 400" class="text-left pl-2">
                  IT팀 없이 클릭만으로 편리한 데이터 업무 지원
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 2 -->
        <div class="d-flex flex-column align-start justify-space-between text-center" style="margin-top: 60px">
          <div style="width: 100%">
            <div style="font-size: 16px; font-weight: 600; color: #283593" class="pb-3">
              의료/바이오/헬스케어
            </div>
            <div style="font-size: 24px; font-weight: 700" class="pb-4">
              의료 정보 표준화 기술
            </div>
            <div style="font-size: 16px; font-weight: 300" class="pb-5">
              미국 국립 보건원(NIH: National Institutes of Health) UMLS(Unified Medical Language System) 데이터베이스를 활용하여 의료 용어 표준화를 지원합니다.
            </div>
            <div style="width: 100%" class="mb-4">
              <img src="@/assets/img/solution_2.png" style="width: 100%; height: auto">
            </div>
            <div class="mb-8">
              <div class="d-flex align-start mb-4">
                <v-icon style="color: #2096f3">mdi-check-circle</v-icon>
                <div style="font-size: 16px; font-weight: 400" class="text-left pl-2">
                  딥러닝 언어모델(LLM: Large Language Model)
                </div>
              </div>
              <div class="d-flex align-start mb-4">
                <v-icon style="color: #2096f3">mdi-check-circle</v-icon>
                <div style="font-size: 16px; font-weight: 400" class="text-left pl-2">
                  지식그래프(Knowledge Graph)
                </div>
              </div>
              <div class="d-flex align-start mb-4">
                <v-icon style="color: #2096f3">mdi-check-circle</v-icon>
                <div style="font-size: 16px; font-weight: 400" class="text-left pl-2">
                  전문용어 추천 기능
                </div>
              </div>
              <div class="d-flex align-start mb-4">
                <v-icon style="color: #2096f3">mdi-check-circle</v-icon>
                <div style="font-size: 16px; font-weight: 400" class="text-left pl-2">
                  편리한 나만의 용어 관리 기능
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 3 -->
        <div class="d-flex flex-column align-start justify-space-between text-center" style="margin-top: 60px">
          <div style="width: 100%">
            <div style="font-size: 16px; font-weight: 600; color: #283593" class="pb-3">
              대학교 및 연구실 대상
            </div>
            <div style="font-size: 24px; font-weight: 700" class="pb-4">
              자동 연구 실적 정보 솔루션
            </div>
            <div style="font-size: 16px; font-weight: 300" class="pb-5">
              논문 PDF를 업로드하면 자동으로 논문명, 저자, 저널명, 학회명, 출판일 등 주요 정보를 대학의 학사시스템에 동기화합니다.
            </div>
            <div style="width: 100%" class="mb-4">
              <img src="@/assets/img/solution_3.png" style="width: 100%; height: auto">
            </div>
            <div class="mb-8">
              <div class="d-flex align-start mb-4">
                <v-icon style="color: #2096f3">mdi-check-circle</v-icon>
                <div style="font-size: 16px; font-weight: 400" class="text-left pl-2">
                  OCR(Optical Character Recognition) 기술
                </div>
              </div>
              <div class="d-flex align-start mb-4">
                <v-icon style="color: #2096f3">mdi-check-circle</v-icon>
                <div style="font-size: 16px; font-weight: 400" class="text-left pl-2">
                  Meta-data 추출 기술
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 4 -->
        <div class="d-flex flex-column align-start justify-space-between text-center" style="margin-top: 60px">
          <div style="width: 100%">
            <div style="font-size: 16px; font-weight: 600; color: #283593" class="pb-3">
              웹/모바일 서비스 기업
            </div>
            <div style="font-size: 24px; font-weight: 700" class="pb-4">
              메타인지 검색 솔루션
            </div>
            <div style="font-size: 16px; font-weight: 300" class="pb-5">
              문서 주요 구간을 포착하는 코그콤만의 특허기술을 활용하여 사용자의 의도를 자동으로 파악합니다.
            </div>
            <div style="width: 100%" class="mb-4">
              <img src="@/assets/img/solution_4.png" style="width: 100%; height: auto">
            </div>
            <div class="mb-8">
              <div class="d-flex align-start mb-4">
                <v-icon style="color: #2096f3">mdi-check-circle</v-icon>
                <div style="font-size: 16px; font-weight: 400" class="text-left pl-2">
                  엘라스틱 스택(Elastic Stack) 기반 검색 솔루션
                </div>
              </div>
              <div class="d-flex align-start mb-4">
                <v-icon style="color: #2096f3">mdi-check-circle</v-icon>
                <div style="font-size: 16px; font-weight: 400" class="text-left pl-2">
                  사용자 히스토리를 영화 필름 보듯 시각적 탐색 제공
                </div>
              </div>
              <div class="d-flex align-start mb-4">
                <v-icon style="color: #2096f3">mdi-check-circle</v-icon>
                <div style="font-size: 16px; font-weight: 400" class="text-left pl-2">
                  딥러닝 언어모델 기반(LLM) 동의어 모델
                </div>
              </div>
              <div class="d-flex align-start mb-4">
                <v-icon style="color: #2096f3">mdi-check-circle</v-icon>
                <div style="font-size: 16px; font-weight: 400" class="text-left pl-2">
                  단어, 문장, 문구, 문서간의 네트워크 분석
                </div>
              </div>
            </div>
            <v-btn color="#367BF5" x-large dark @click="goInventier()">인벤티어 바로가기</v-btn>
          </div>
        </div>
        <!-- 5 -->
        <div class="d-flex flex-column align-start justify-space-between text-center" style="margin-top: 60px">
          <div style="width: 100%">
            <div style="font-size: 16px; font-weight: 600; color: #283593" class="pb-3">
              비즈니스/법률/특허 문서
            </div>
            <div style="font-size: 24px; font-weight: 700" class="pb-4">
              인공지능 문서 요약 솔루션
            </div>
            <div style="font-size: 16px; font-weight: 300" class="pb-5">
              기업에서 운영하는 서비스의 문서, 텍스트를 고객에게 요약된 형태로 제공합니다.
            </div>
            <div style="width: 100%" class="mb-4">
              <img src="@/assets/img/solution_5.png" style="width: 100%; height: auto">
            </div>
            <div class="mb-8">
              <div class="d-flex align-start mb-4">
                <v-icon style="color: #2096f3">mdi-check-circle</v-icon>
                <div style="font-size: 16px; font-weight: 400" class="text-left pl-2">
                  검색 솔루션 연계 문서 자동 요약 기술
                </div>
              </div>
              <div class="d-flex align-start mb-4">
                <v-icon style="color: #2096f3">mdi-check-circle</v-icon>
                <div style="font-size: 16px; font-weight: 400" class="text-left pl-2">
                  최신 딥러닝 언어 모델 기반의 AI 자연어처리 엔진
                </div>
              </div>
              <div class="d-flex align-start mb-4">
                <v-icon style="color: #2096f3">mdi-check-circle</v-icon>
                <div style="font-size: 16px; font-weight: 400" class="text-left pl-2">
                  인공지능 그래프 분석 알고리즘으로 주요 내용 제공
                </div>
              </div>
              <div class="d-flex align-start mb-4">
                <v-icon style="color: #2096f3">mdi-check-circle</v-icon>
                <div style="font-size: 16px; font-weight: 400" class="text-left pl-2">
                  다양한 단위의 텍스트 임베딩(Embedding) 모델
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { SplitCarousel, SplitCarouselItem } from "vue-split-carousel"

export default {
  name: 'Home',

  components: {
    SplitCarousel,
    SplitCarouselItem
  },

  data() {
    return {
      autoplay: true,
      loop: true,
      speed: 500,
      interval: 2000,
      displayAmount: 4,
      arrowVisible: 'enable',
      itemAlign: 'center',
      hoverCancelPlay: true,
      arrowAreaWidth: 0,
      playDirection: 'ltr',
      timingFuntion: 'ease',
      carouselKey1: Math.floor(Math.random() * Math.floor(Number.MAX_SAFE_INTEGER)),
      carouselKey2: Math.floor(Math.random() * Math.floor(Number.MAX_SAFE_INTEGER)),
      carouselKey3: Math.floor(Math.random() * Math.floor(Number.MAX_SAFE_INTEGER)),
      currDisplay: 1,
      mobileItemSize: 120,

      showAlert: false,
    }
  },

  methods: {
    clickContactUs() {
      window.scroll({
        top: document.body.scrollHeight,
        left: 0,
        behavior: 'smooth'
      })
    },
    goContact() {
      this.$router.push('/contact').catch(() => {})
      scrollTo(0, 0)
    },
    goAiVoucher() {
      this.$router.push('/ai-voucher').catch(() => {})
      scrollTo(0, 0)
    },
    goSolution() {
      this.$router.push('/solution').catch(() => {})
      scrollTo(0, 0)
    },
    goInventier() {
      // console.log('goInventier')
      window.open('https://inventier.cogcom.kr/')
    },
    goRecolumn() {
      console.log('goRecolumn')
      window.open('https://www.recolumn.com/')
    },
    copyTemplate() {
      const t = document.createElement("textarea")
      document.body.appendChild(t)
      t.value = '1. 문의 종류\n\n2. 문의 내용'
      t.select()
      document.execCommand('copy')
      document.body.removeChild(t)
      this.showAlert = true
      setTimeout(() => {
        // this.showAlert = false
      }, 3000)
    },

    checkScreenSize() {
      let bodyWidth = window.innerWidth
      // console.log(bodyWidth)
      if (bodyWidth >= 1919) {
        this.currDisplay = 1
      } else if (bodyWidth >= 600) {
        this.currDisplay = 2
      } else {
        this.currDisplay = 3
        this.mobileItemSize = bodyWidth / 3
      }
      // console.log(this.currDisplay)
    }
  },

  mounted() {
    window.addEventListener('resize', this.checkScreenSize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkScreenSize)
  },

  watch: {
    currDisplay(value) {
      // console.log(value)
      if (value === 1) this.carouselKey1 += 1
      else if (value === 2) this.carouselKey2 += 1
      else if (value === 3) this.carouselKey3 += 1
    }
  },
}
</script>

<style scoped>
div {
  /* word-break: keep-all; */
  word-break: break-all;
}

.first-section {
  background: url('../assets/img/first_section.png');
  background-size: 100% 100%;
}
.first-section-mobile {
  background: url('../assets/img/mobile_first_section.png');
  background-size: 100% 100%;
}

.carousel-item {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border: 0px #dcdfe6 solid;
  height: 100%;
}

.ai-solution-title {
  font-size: 36px;
  font-weight: 700;
}
.ai-solution-body {
  font-size: 20px;
  font-weight: 300;
}

@media (min-width: 2501px) {
  .first-section-title-1 {
    font-size: 56px;
  }
  .first-section-title-2 {
    font-size: 28px;
  }

  .solution-section-title-1 {
    font-size: 48px;
  }
  .solution-section-title-2 {
    font-size: 24px;
  }

  .solution-col-1 {
    padding: 0 48px 48px 0 !important;
  }
  .solution-col-2 {
    padding: 0 0 48px 48px !important;
  }
  .solution-col-3 {
    padding: 48px 48px 0 0 !important;
  }
  .solution-col-4 {
    padding: 48px 0 0 48px !important;
  }

  .solution-section-sub-1 {
    font-size: 24px;
    font-weight: 600;
  }
  .solution-section-sub-2 {
    font-size: 18px;
  }

  .partners {
    width: 60%;
  }
  .partner-box {
    width: 210px;
    height: 120px;
    margin: 32px auto;
  }
}

@media (max-width: 2500px) and (min-width: 1905px) {
  .first-section-title-1 {
    font-size: 40px;
  }
  .first-section-title-2 {
    font-size: 20px;
  }

  .solution-section-title-1 {
    font-size: 48px;
  }
  .solution-section-title-2 {
    font-size: 24px;
  }

  .solution-col-1 {
    padding: 0 48px 48px 0 !important;
  }
  .solution-col-2 {
    padding: 0 0 48px 48px !important;
  }
  .solution-col-3 {
    padding: 48px 48px 0 0 !important;
  }
  .solution-col-4 {
    padding: 48px 0 0 48px !important;
  }

  .solution-section-sub-1 {
    font-size: 24px;
    font-weight: 600;
  }
  .solution-section-sub-2 {
    font-size: 18px;
  }

  .partners {
    width: 60%;
  }
  .partner-box {
    width: 210px;
    height: 120px;
    margin: 32px auto;
  }
}

@media (max-width: 1904px) and (min-width: 1501px) {
  .first-section-title-1 {
    font-size: 40px;
  }
  .first-section-title-2 {
    font-size: 20px;
  }

  .solution-section-title-1 {
    font-size: 48px;
  }
  .solution-section-title-2 {
    font-size: 24px;
  }

  .solution-col-1 {
    padding: 0 48px 48px 0 !important;
  }
  .solution-col-2 {
    padding: 0 0 48px 48px !important;
  }
  .solution-col-3 {
    padding: 48px 48px 0 0 !important;
  }
  .solution-col-4 {
    padding: 48px 0 0 48px !important;
  }

  .solution-section-sub-1 {
    font-size: 24px;
    font-weight: 600;
  }
  .solution-section-sub-2 {
    font-size: 18px;
  }

  .partners {
    width: 70%;
  }
  .partner-box {
    width: 210px;
    height: 120px;
    margin: 32px auto;
  }
}

@media (max-width: 1500px) and (min-width: 1265px) {
  .first-section-title-1 {
    font-size: 32px;
  }
  .first-section-title-2 {
    font-size: 18px;
  }

  .solution-section-title-1 {
    font-size: 48px;
  }
  .solution-section-title-2 {
    font-size: 24px;
  }

  .solution-col-1 {
    padding: 0 48px 48px 0 !important;
  }
  .solution-col-2 {
    padding: 0 0 48px 48px !important;
  }
  .solution-col-3 {
    padding: 48px 48px 0 0 !important;
  }
  .solution-col-4 {
    padding: 48px 0 0 48px !important;
  }

  .solution-section-sub-1 {
    font-size: 24px;
    font-weight: 600;
  }
  .solution-section-sub-2 {
    font-size: 18px;
  }

  .partners {
    width: 80%;
  }
  .partner-box {
    width: 210px;
    height: 120px;
    margin: 32px auto;
  }
}

@media (max-width: 1264px) and (min-width: 961px) {
  .first-section-title-1 {
    font-size: 40px;
  }
  .first-section-title-2 {
    font-size: 20px;
  }

  .solution-section-title-1 {
    font-size: 48px;
  }
  .solution-section-title-2 {
    font-size: 24px;
  }

  .solution-col-1 {
    padding: 0 48px 48px 0 !important;
  }
  .solution-col-2 {
    padding: 0 0 48px 48px !important;
  }
  .solution-col-3 {
    padding: 48px 48px 0 0 !important;
  }
  .solution-col-4 {
    padding: 48px 0 0 48px !important;
  }

  .solution-section-sub-1 {
    font-size: 24px;
    font-weight: 600;
  }
  .solution-section-sub-2 {
    font-size: 18px;
  }

  .partners {
    width: 80%;
  }
  .partner-box {
    width: 210px;
    height: 120px;
    margin: 32px auto;
  }
}

@media (max-width: 960px) and (min-width: 601px) {
  .first-section {
    background-size: 150% 130% !important;
  }

  .first-section-title-1 {
    font-size: 36px;
  }
  .first-section-title-2 {
    font-size: 18px;
  }

  .solution-section-title-1 {
    font-size: 48px;
  }
  .solution-section-title-2 {
    font-size: 24px;
  }

  .solution-col-1 {
    padding-bottom: 48px !important;
  }
  .solution-col-2 {
    padding-bottom: 48px !important;
  }
  .solution-col-3 {
    padding-bottom: 48px !important;
  }
  .solution-col-4 {
    padding-bottom: 48px !important;
  }

  .solution-section-sub-1 {
    font-size: 24px;
    font-weight: 600;
  }
  .solution-section-sub-2 {
    font-size: 18px;
  }

  .partners {
    width: 100%;
  }
  .partner-box {
    width: 210px;
    height: 120px;
    margin: 32px auto;
  }
}

@media (max-width: 600px) {
  .first-section {
    background-size: 150% 130% !important;
  }

  .first-section-title-1 {
    font-size: 28px;
  }
  .first-section-title-2 {
    font-size: 14px;
  }

  .partner-section {
    height: auto !important;
    padding: 60px 0 60px 0 !important;
  }
  .partners {
    width: 100%;
  }
  .partner-box {
    width: 150px;
    height: 90px;
    margin: 24px auto;
  }

  .solution-section {
    padding: 90px 0 30px 0 !important;
  }
  .solution-section-title-1 {
    font-size: 24px;
  }
  .solution-section-title-2 {
    font-size: 18px;
  }
  .solution-section-sub-1 {
    font-size: 16px;
    font-weight: 600;
  }
  .solution-section-sub-2 {
    font-size: 12px;
  }
  .solution-col-1 {
    padding-bottom: 48px !important;
  }
  .solution-col-2 {
    padding-bottom: 48px !important;
  }
  .solution-col-3 {
    padding-bottom: 48px !important;
  }
  .solution-col-4 {
    padding-bottom: 48px !important;
  }

  .introduce-section {
    padding: 90px 0 30px 0 !important;
  }
  .introduce-section-title-1 {
    font-size: 32px !important;
  }
  .introduce-section-title-2 {
    font-size: 20px !important;
  }
  .introduce-section-img {
    width: 100% !important;
  }

  .feature-title {
    font-size: 32px !important;
  }
  .feature-section {
    padding: 60px 0 !important;
  }
  .feature-section-title {
    font-size: 20px !important;
    text-align: center !important;
  }
  .feature-section-sub {
    font-size: 14px !important;
    text-align: center !important;
  }

  .usecase-title {
    font-size: 32px !important;
  }
  .usecase-section {
    padding: 60px 0 !important;
  }
  .usecase-section-card {
    padding: 30px !important;
  }
  .usecase-section-title {
    font-size: 20px !important;
  }
  .usecase-section-sub {
    font-size: 14px !important;
  }
  .usecase-section-description {
    font-size: 14px !important;
    padding-left: 8px;
  }

  .contact-section-title {
    font-size: 20px !important;
  }
  .contact-section-context {
    font-size: 12px !important;
  }

}

.shadow-box {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.07), 0 2px 2px 0 rgba(0,0,0, 0.07), 0 1px 5px 0 rgba(0,0,0, 0.07) !important;
  background-color: white;
}
.disabled-chip::before {
  display: none;
}
</style>